<template>
  <div class="section8">
    <transition-group name="fade-up" mode="out-in">
      <div class="swipe absolute" @mouseenter.stop="toggleTimer = false" @mouseleave.stop="toggleTimer = true" v-if="blockIndex === 0" key="swipe-0">
        <div class="swipe-wrap relative" v-touch:swipe.left="decIndex" v-touch:swipe.right="addIndex">
          <transition-group name="swipe-fade" mode="out-in">
            <div v-for="(slide, i) in slideList" v-show="slideIndex === i" :key="slide.img" :class="`swipe-item absolute`">
              <img loading="lazy" :src="slide.img" alt="">
              <div class="slide-name absolute" v-html="slide.name"></div>
            </div>
          </transition-group>
          <div class="pagination absolute flex-ac" v-if="isPC">
            <div :class="`pagination-dot`" v-for="(slide, index) in slideList" :key="slide.img + '-dot'" @click="goTo(index)"><span :class="`${slideIndex === index ? 'active' : ''}`"></span></div>
          </div>
          <div class="swipe-btns absolute flex-ac flex-jb">
            <img loading="lazy" src="./all/prev-btn.png" alt="" class="prev-btn" @click="decIndex">
            <img loading="lazy" src="./all/next-btn.png" alt="" class="next-btn" @click="addIndex">
          </div>
        </div>
      </div>
      <div class="swipe absolute" @mouseenter.stop="toggleTimer = false" @mouseleave.stop="toggleTimer = true" v-if="blockIndex === 1" key="swipe-1">
        <div class="swipe-wrap relative" v-touch:swipe.left="decIndex" v-touch:swipe.right="addIndex">
          <transition-group name="swipe-fade" mode="out-in">
            <div v-for="(slide, i) in slideList1" v-show="slideIndex1 === i" :key="slide.img" :class="`swipe-item absolute`">
              <img loading="lazy" :src="slide.img" alt="">
              <div class="slide-name absolute" v-html="slide.name"></div>
            </div>
          </transition-group>
          <div class="pagination absolute flex-ac" v-if="isPC">
            <div :class="`pagination-dot`" v-for="(slide, index) in slideList1" :key="slide.img + '-dot'" @click="goToMultiIndex(index, 1)"><span :class="`${slideIndex1 === index ? 'active' : ''}`"></span></div>
          </div>
          <div class="swipe-btns absolute flex-ac flex-jb">
            <img loading="lazy" src="./all/prev-btn.png" alt="" class="prev-btn" @click="decMultiIndex(1)">
            <img loading="lazy" src="./all/next-btn.png" alt="" class="next-btn" @click="addMultiIndex(1)">
          </div>
        </div>
      </div>
    </transition-group>
    <transition name="fade-up" mode="out-in">
      <div v-if="blockIndex === 1 || blockIndex === 0">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve" class="img" v-if="viewIndex === 8 || viewIndex === 9">
          <g id="圖層_1">
          </g>
          <g id="圖層_3">
            <g id="外圓圈_1_" class="an01">
              <path class="st0" d="M237.7,166c9.6-27,9.1-56.8-1.5-83.5" />
              <path class="st0" d="M168.1,15.7c-25.8-9.7-54.3-10.1-80.4-1" />
              <path class="st0" d="M17.3,81c-11.3,27.2-11.9,57.8-1.9,85.5" />
              <path class="st0" d="M79.4,234.5c28.8,12.5,61.7,13,90.8,1.4" />
            </g>
            <g id="內大圓圈">
              <circle class="st1 an03" cx="126.4" cy="126.2" r="52.4" />
            </g>
            <g id="線條_1_" class="an04">
              <path class="st3" d="M174.6,126.6L222,79.2c13.1-13.1,13.1-34.4,0-47.5l0,0c-13.1-13.1-34.4-13.1-47.5,0l-47.4,47.4" />
              <path class="st3" d="M79.6,126.6l-47.4,47.4c-13.1,13.1-13.1,34.4,0,47.5h0c13.1,13.1,34.4,13.1,47.5,0l47.4-47.4" />
              <path class="st3" d="M127.1,79.1L79.8,31.7c-13.1-13.1-34.4-13.1-47.5,0l0,0c-13.1,13.1-13.1,34.4,0,47.5l47.4,47.4" />
              <path class="st3" d="M127.1,174.1l47.4,47.4c13.1,13.1,34.4,13.1,47.5,0h0c13.1-13.1,13.1-34.4,0-47.5l-47.4-47.4" />
            </g>
            <g id="文字" class="anfont">
              <path class="st2-66" d="M174.8,74.1c0,0,0.9,0.7,1.4,1.2c0,0.1-0.2,0.2-0.4,0.2h-11.6l-0.1-0.4h2.9c0.4-1.3,0.8-3.2,1.2-5h-2.5
		l-0.1-0.4h2.7c0.3-1.2,0.5-2.5,0.7-3.5H165l-0.1-0.4h8.3l0.8-1c0,0,0.9,0.7,1.4,1.2c0,0.1-0.2,0.2-0.4,0.2h-4.9
		c-0.2,1-0.4,2.2-0.7,3.5h2.5l0.6-0.7l1.3,1c-0.1,0.1-0.3,0.2-0.5,0.3v4.8h0.6L174.8,74.1z M169.4,70.2c-0.4,1.8-0.8,3.7-1.1,5h3.9
		v-5H169.4z M185.4,69.8c0.8,1,2.4,1.6,3.7,1.8l0,0.2c-0.5,0.2-0.9,0.6-1,1.1c-1.3-0.6-2.4-1.7-3.1-3h-3.7
		c-0.4,0.5-0.9,1.1-1.5,1.5c2.9,0.4,1.9,2.4,0.8,1.7c-0.1-0.5-0.6-1.2-1-1.6c-0.7,0.6-1.6,1.1-2.6,1.5l-0.1-0.1
		c1.3-0.8,2.4-1.8,3.1-2.9h-2.9l-0.1-0.4h3.2c0.3-0.5,0.5-0.9,0.8-1.4h-2.7l-0.1-0.4h3c0.2-0.5,0.3-0.9,0.5-1.4h-3.9l-0.1-0.4h4.1
		c0.2-0.6,0.3-1.1,0.3-1.7l1.8,0.4c0,0.2-0.2,0.3-0.5,0.3c-0.1,0.3-0.2,0.6-0.2,1h3.2l0.7-0.9c0,0,0.8,0.6,1.3,1
		c0,0.1-0.2,0.2-0.3,0.2h-5c-0.1,0.5-0.3,0.9-0.5,1.4h3.1l0.7-0.8c0,0,0.8,0.6,1.2,1c0,0.1-0.2,0.2-0.3,0.2h-4.8
		c-0.2,0.5-0.5,1-0.8,1.4h5.3l0.7-0.9c0,0,0.8,0.6,1.4,1.1c0,0.1-0.2,0.2-0.4,0.2H185.4z M186.4,72c-0.1,0.1-0.3,0.2-0.5,0.1
		c-0.5,0.3-1,0.7-1.5,0.9c4,1.2,3.1,3.2,1.9,2.6c-0.4-0.6-1.5-1.6-2.9-2.5v1.8c0,0.8-0.2,1.3-1.6,1.4c0-0.3-0.1-0.6-0.3-0.7
		c-0.2-0.2-0.4-0.3-1-0.4V75c0,0,1.2,0.1,1.4,0.1c0.2,0,0.2-0.1,0.2-0.2v-1.6c-0.7,0.5-1.7,1.2-2.9,2c0,0.2-0.1,0.3-0.2,0.4
		l-1.1-1.1c0.8-0.2,2.4-0.9,4.2-1.7v-2.8l1.6,0.2c0,0.2-0.2,0.3-0.5,0.3v2.1c0.2,0,0.3,0.1,0.5,0.1c0.3-0.5,0.7-1.1,1.1-1.8
		L186.4,72z M194.1,69.8c-0.4,0.3-0.9,0.6-1.5,0.9v4.1c0,0.9-0.2,1.4-1.5,1.5c0-0.4-0.1-0.7-0.2-0.9c-0.2-0.2-0.4-0.3-0.9-0.4v-0.2
		c0,0,1.1,0.1,1.3,0.1c0.2,0,0.3-0.1,0.3-0.2v-3.3c-0.3,0.2-0.6,0.4-1,0.6c0,0.2-0.1,0.3-0.3,0.3l-0.6-1.5c0.4-0.1,1-0.3,1.8-0.5
		v-2.7H190l-0.1-0.4h1.6v-3l1.6,0.2c0,0.2-0.2,0.3-0.5,0.4v2.5h0.1l0.6-0.9c0,0,0.6,0.6,1,1.1c0,0.1-0.2,0.2-0.3,0.2h-1.4V70
		c0.5-0.1,1-0.3,1.4-0.4L194.1,69.8z M198,74.8c0.4,0.1,0.9,0.1,1.4,0.1c0.6,0,2,0,2.6,0V75c-0.4,0.1-0.6,0.5-0.6,1h-2.1
		c-2.1,0-3.4-0.5-4.1-2.4c-0.5,1.3-1.3,2.2-2.3,2.7l-0.2-0.1c1-1.1,1.6-2.6,1.7-4.6l1.7,0.3c0,0.2-0.2,0.3-0.5,0.3
		c-0.1,0.4-0.2,0.8-0.3,1.1c0.4,0.6,0.8,1,1.5,1.2V71h-2.4l-0.1-0.4h2.5v-1.4h-3.1l-0.1-0.4h3.2v-1.3h-2.3l-0.1-0.4h2.4v-1.1h-2.9
		l-0.1-0.4h3v-1.4l1.6,0.2c0,0.2-0.1,0.3-0.5,0.4v0.9h1.7l0.7-0.9c0,0,0.8,0.6,1.3,1.1c0,0.1-0.2,0.2-0.3,0.2H198v1.1h1.3l0.6-0.6
		l1.2,0.9c-0.1,0.1-0.2,0.2-0.5,0.2v1.1h0.1l0.5-0.8c0,0,0.6,0.6,0.9,0.9c0,0.1-0.2,0.2-0.3,0.2h-1.2v1.9c0,0.1-0.6,0.3-1,0.3h-0.2
		V71H198v1.6h1.2l0.7-0.9c0,0,0.8,0.6,1.2,1c0,0.1-0.2,0.2-0.3,0.2H198V74.8z M198,68.9h1.5v-1.3H198V68.9z M198,70.6h1.5v-1.4H198
		V70.6z M207,70.7c-0.1,0.1-0.2,0.1-0.5,0.2c-0.2,0.9-0.8,2-1.6,2.8c0.1,0,0.1,0.1,0.2,0.1c1.1,1,2.6,1.2,5.3,1.1
		c1.5,0,2.8,0,4.3-0.1V75c-0.5,0.1-0.8,0.5-0.9,1h-3.9c-2.7,0-3.9-0.5-5-1.8c-0.2-0.2-0.3-0.2-0.4,0c-0.2,0.4-0.6,1.1-0.9,1.7
		c0,0.1,0,0.2-0.1,0.3l-0.9-1.2c0.6-0.4,1.5-1.1,1.9-1.3c0.4-0.8,0.8-1.9,0.9-2.9h-1l-0.4,0.4l-0.9-0.7c0.1-0.1,0.3-0.2,0.5-0.2
		c0.4-0.6,1-1.5,1.4-2.1h-2l-0.1-0.4h2.1l0.5-0.6l1.1,1c-0.1,0.1-0.3,0.2-0.6,0.2c-0.3,0.6-1,1.5-1.4,2.1h0.7l0.6-0.6L207,70.7z
		 M203.6,64.5c3,0.7,2.1,2.8,1,2c-0.1-0.7-0.6-1.5-1.1-2L203.6,64.5z M207.6,65.6c0,0.6-0.3,1.1-0.7,1.3s-0.9,0.1-1-0.3
		c-0.1-0.4,0.1-0.7,0.5-0.8c0.4-0.2,0.8-0.7,0.8-1.2l0.2,0c0.1,0.3,0.2,0.5,0.2,0.7h5.2l0.6-0.6l1.1,1.1c-0.1,0.1-0.2,0.1-0.5,0.2
		c-0.2,0.3-0.6,0.6-1,0.9c0.2,0.2,0.5,0.4,0.7,0.6c0,0.1-0.2,0.2-0.3,0.2h-2.8v1h1.2l0.6-0.6l1.2,0.9c-0.1,0.1-0.3,0.2-0.5,0.3v2.9
		c0,0.1-0.6,0.3-0.9,0.3h-0.2v-0.4h-1.4v1.1h1.7l0.7-0.8c0,0,0.8,0.6,1.2,1c0,0.1-0.2,0.2-0.3,0.2h-3.2v1.2c0,0.1-0.5,0.4-0.9,0.4
		h-0.2v-1.6h-3l-0.1-0.4h3.1v-1.1h-1.4V72c0,0.1-0.5,0.3-0.9,0.3h-0.2v-4.4l1.2,0.5h1.3v-1h-2.7l-0.1-0.4h2.8v-1.2l1.6,0.2
		c0,0.2-0.1,0.3-0.5,0.4v0.7h1.3l0.6-0.8l0.2,0.1c0-0.3,0.1-0.6,0.1-0.9H207.6z M208.2,68.8v1.1h1.4v-1.1H208.2z M209.6,71.4v-1.1
		h-1.4v1.1H209.6z M210.7,68.8v1.1h1.4v-1.1H210.7z M212.1,71.4v-1.1h-1.4v1.1H212.1z" />
              <path class="st2-w" d="M42.1,198.2l1-1.3c0,0,1.1,0.9,1.7,1.5c0,0.2-0.2,0.2-0.4,0.2H33l-0.1-0.4H42.1z M56.1,193.4
		c0,0,0.9,0.7,1.5,1.1c0,0.1-0.2,0.2-0.4,0.2H45.9l-0.1-0.4h5.6c-0.2-0.6-0.7-1.2-1.2-1.6l0.1-0.1c2.3,0,2.5,1.2,2,1.7h3
		L56.1,193.4z M56.1,198.4l1.3,1c-0.1,0.1-0.3,0.2-0.5,0.3v3.7c0,0.8-0.2,1.3-1.6,1.4c0-0.4-0.1-0.6-0.3-0.8
		c-0.2-0.2-0.5-0.3-1-0.4v-0.2c0,0,1.2,0.1,1.5,0.1c0.2,0,0.3-0.1,0.3-0.3v-3.8h-7.8v4.9c0,0.1-0.5,0.4-1,0.4h-0.2v-6.2l1.3,0.5
		h7.6L56.1,198.4z M53.6,198.6V198h-3.9v0.3c0,0.1-0.5,0.4-1,0.4h-0.2v-3.4l1.3,0.5h3.7l0.6-0.6l1.3,1c-0.1,0.1-0.3,0.2-0.5,0.3
		v1.9c0,0.1-0.6,0.3-1,0.3H53.6z M54,203c0,0.1-0.6,0.3-1,0.3h-0.2v-0.6h-2.4v0.5c0,0.1-0.5,0.3-1,0.3h-0.2V200l1.2,0.5h2.3
		l0.6-0.6l1.2,0.9c-0.1,0.1-0.2,0.2-0.5,0.3V203z M53.6,197.7v-1.5h-3.9v1.5H53.6z M52.9,202.4v-1.5h-2.4v1.5H52.9z M69.2,202.6
		c0,0,0.9,0.7,1.4,1.2c0,0.1-0.2,0.2-0.4,0.2H58.6l-0.1-0.4h2.9c0.4-1.3,0.8-3.2,1.2-5H60l-0.1-0.4h2.7c0.3-1.2,0.5-2.5,0.7-3.5
		h-3.9l-0.1-0.4h8.3l0.8-1c0,0,0.9,0.7,1.4,1.2c0,0.1-0.2,0.2-0.4,0.2h-4.9c-0.2,1-0.4,2.2-0.7,3.5h2.5l0.6-0.7l1.3,1
		c-0.1,0.1-0.3,0.2-0.5,0.3v4.8h0.6L69.2,202.6z M63.8,198.7c-0.4,1.8-0.8,3.7-1.1,5h3.9v-5H63.8z M76,203.4c0,0.8-0.2,1.3-1.5,1.4
		c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.9-0.4v-0.2c0,0,1.1,0.1,1.3,0.1c0.2,0,0.3-0.1,0.3-0.3V199c-0.3,0.3-0.6,0.7-1,1.2
		c0,0.2-0.1,0.3-0.2,0.4l-0.5-0.9c-0.1,1.8-0.5,3.7-1.7,5.1l-0.2-0.1c0.9-2.1,0.9-4.7,0.9-7.1v-4.5l1.2,0.5h1.4l0.5-0.6l1.2,0.9
		c-0.1,0.1-0.3,0.2-0.5,0.2V203.4z M73.2,197.6c0,0.6,0,1.1,0,1.8c0.4-0.2,1-0.5,1.7-0.8v-1.3c-0.1,0-0.2,0.1-0.4,0
		c-0.3-0.3-0.8-0.6-1.3-0.8V197.6z M73.2,194v2c0.9-0.1,1.4,0,1.7,0.3V194H73.2z M82.7,199.1c-0.1,0.1-0.2,0.2-0.5,0.2
		c-0.5,1.1-1.1,2.2-2,3c0.9,0.6,2,1,3.2,1.3l0,0.1c-0.5,0.1-0.8,0.4-1,1c-1.2-0.4-2.1-1-2.9-1.8c-1,0.8-2.2,1.3-3.7,1.8l-0.1-0.2
		c1.3-0.5,2.4-1.3,3.3-2.1c-0.7-0.9-1.2-1.9-1.5-3.3h-0.9l-0.1-0.4h4.5l0.7-0.7L82.7,199.1z M76.2,198.4c1.2-1.1,1.3-2.5,1.3-3.6
		v-1.6l1.3,0.5h1.4l0.6-0.6l1.1,0.9c-0.1,0.1-0.2,0.2-0.5,0.2v2.4c0,0.1,0,0.2,0.2,0.2H82h0.3c0.1,0,0.2,0,0.2,0
		c0.1,0,0.2,0,0.3-0.1h0.1l0.1,0c0.3,0.1,0.4,0.2,0.4,0.4c0,0.4-0.4,0.6-1.5,0.6h-0.6c-0.8,0-0.9-0.2-0.9-0.9v-2.8h-1.7v0.7
		c0,1.1-0.2,2.8-2.3,3.8L76.2,198.4z M77.6,199.1c0.4,1.1,1,2,1.8,2.7c0.7-0.8,1.1-1.7,1.5-2.7H77.6z M48.6,210.7
		c-0.1,0.1-0.2,0.2-0.5,0.2c-0.5,1-1.2,2-2.1,2.8c1.5,0.9,3.4,1.5,5.3,1.9l0,0.2c-0.6,0.1-1,0.5-1.1,1.1c-1.9-0.5-3.6-1.3-4.9-2.5
		c-1.5,1.2-3.5,2-6,2.5l-0.1-0.2c2.3-0.6,4.1-1.7,5.4-3c-0.8-0.9-1.5-2-1.9-3.3l0.2-0.1c0.5,1.1,1.3,2.1,2.3,2.8
		c0.8-0.9,1.4-2,1.8-3.1L48.6,210.7z M39.5,207.6l-0.1-0.4H49l0.8-1.1c0,0,0.9,0.7,1.4,1.2c0,0.1-0.2,0.2-0.4,0.2H39.5z
		 M39.7,211.5c1.3-1,2.4-2.5,2.9-3.7l1.6,0.8c-0.1,0.1-0.2,0.2-0.5,0.2c-0.8,1.1-2.2,2.3-3.9,2.9L39.7,211.5z M43.6,204.8
		c3.4,0.5,2.5,3,1.2,2.2c-0.2-0.7-0.8-1.6-1.4-2.1L43.6,204.8z M46.6,208c4.8,1.1,4.2,4,2.8,3.3c-0.5-1.1-1.8-2.4-2.9-3.2L46.6,208
		z M52.2,207.9c3.2,0.2,2.3,2.5,1.2,1.8c-0.2-0.6-0.8-1.3-1.3-1.7L52.2,207.9z M56.1,207.7c-0.5,1.4-1.5,4.1-1.7,4.7
		c-0.1,0.4-0.2,0.8-0.2,1.1c0,0.6,0.5,1.1,0.4,2.3c0,0.6-0.4,1-0.9,1c-0.3,0-0.5-0.2-0.6-0.7c0.2-1.4,0.2-2.5-0.1-2.7
		c-0.2-0.1-0.4-0.2-0.7-0.2v-0.3h0.6c0.2,0,0.2,0,0.4-0.3c0.3-0.5,0.3-0.5,2.6-5.1L56.1,207.7z M53.2,205c3.3,0.4,2.3,2.8,1.2,2
		c-0.2-0.7-0.8-1.4-1.3-1.9L53.2,205z M56.6,210.7l1.5,0.2c0,0.2-0.1,0.3-0.4,0.3v1.1c0,1.5-0.4,3.5-2.6,4.6l-0.1-0.1
		c1.5-1.2,1.7-3,1.7-4.5V210.7z M55.7,206.8h2.2c0.4-0.7,0.7-1.4,0.9-2l1.8,0.7c-0.1,0.1-0.2,0.2-0.5,0.2c-0.2,0.3-0.5,0.7-0.8,1.1
		H62l0.7-0.9c0,0,0.8,0.6,1.3,1.1c0,0.1-0.2,0.2-0.4,0.2h-4.7c-0.6,0.8-1.4,1.5-2.1,2.1c1.1-0.1,2.8-0.2,4.6-0.3
		c-0.3-0.4-0.6-0.7-0.9-0.9l0.1-0.1c3.5,0.8,2.7,3.2,1.5,2.5c-0.1-0.4-0.3-0.8-0.6-1.1c-1.2,0.3-3,0.7-5,1
		c-0.1,0.2-0.2,0.3-0.3,0.3l-0.5-1.3c0,0,0.3-0.1,0.4-0.2c0.4-0.4,1-1.2,1.4-2h-1.8L55.7,206.8z M58.7,210.7l1.5,0.2
		c0,0.2-0.1,0.3-0.4,0.3v5c0,0.1-0.5,0.3-0.9,0.3h-0.2V210.7z M63.8,215.5c0.3,0.1,0.4,0.2,0.4,0.5c0,0.4-0.4,0.6-1.6,0.6h-0.6
		c-0.8,0-1-0.3-1-1v-4.9l1.5,0.1c0,0.2-0.1,0.3-0.4,0.3v4.1c0,0.2,0,0.2,0.2,0.2h0.4h0.4c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3-0.1
		L63.8,215.5L63.8,215.5z M69.5,211.3c-0.1,0.1-0.2,0.2-0.5,0.2c-0.3,0.9-0.9,1.9-1.8,2.7c0.1,0,0.1,0.1,0.2,0.1
		c1.1,1,2.6,1.1,5.3,1.1c1.5,0,2.8,0,4.3-0.1v0.2c-0.5,0.1-0.8,0.5-0.8,1h-3.9c-2.7,0-3.9-0.4-5-1.8c-0.2-0.2-0.3-0.2-0.4,0
		c-0.2,0.4-0.6,1.1-0.9,1.7c0,0.1,0,0.2-0.1,0.3l-0.9-1.2c0.6-0.4,1.5-1.2,1.9-1.3c0.5-0.8,0.8-1.8,1-2.8h-1.1l-0.4,0.4l-1-0.8
		c0.1-0.1,0.3-0.2,0.5-0.2c0.4-0.6,1-1.5,1.3-2.2h-2l-0.1-0.4h2.1l0.6-0.6l1.1,1c-0.1,0.1-0.4,0.2-0.7,0.2
		c-0.3,0.6-0.9,1.5-1.4,2.1h0.9l0.6-0.6L69.5,211.3z M65.7,205c3.2,0.5,2.3,2.8,1.1,2.1c-0.1-0.7-0.7-1.5-1.2-2L65.7,205z
		 M69,207.4l-0.1-0.4h4.2c0.2-0.7,0.5-1.6,0.6-2.2l1.7,0.4c0,0.1-0.2,0.3-0.5,0.3c-0.3,0.5-0.9,1.1-1.5,1.5h1.2l0.7-0.9
		c0,0,0.8,0.6,1.3,1.1c0,0.1-0.2,0.2-0.3,0.2h-3.4c-0.2,0.4-0.5,0.9-0.8,1.2h2l0.6-0.6l1.2,0.9c-0.1,0.1-0.3,0.2-0.5,0.3v5.3
		c0,0.1-0.6,0.3-1,0.3h-0.2V214h-3.3v0.5c0,0.1-0.5,0.4-1,0.4h-0.2v-6.8l1.2,0.5h0.5c0-0.4,0.1-0.9,0.1-1.2H69z M70.1,204.9
		c2.8,0.6,1.7,2.7,0.7,1.9c-0.1-0.6-0.5-1.4-0.8-1.8L70.1,204.9z M70.9,209v1.3h3.3V209H70.9z M70.9,211.9h3.3v-1.3h-3.3V211.9z
		 M74.2,213.6v-1.3h-3.3v1.3H74.2z" />
              <path class="st2-66" d="M66.5,49.6c-2.6,0.6-4,2.1-4.2,4.3c0.6-0.6,1.3-0.9,2.1-0.9c1.6,0,2.6,1.1,2.6,2.8c0,1.9-1.2,3-3,3
		c-2,0-3.2-1.5-3.2-3.9c0-2.8,2.1-5.2,5.6-5.6L66.5,49.6z M65.5,55.9c0-1.5-0.6-2.2-1.6-2.2c-0.6,0-1.2,0.2-1.7,0.6
		c0,0.1,0,0.2,0,0.3c0,2.4,0.7,3.6,1.8,3.6C64.9,58.2,65.5,57.5,65.5,55.9z M74.5,55.9H73v2.6h-1.3v-2.6h-3.8v-0.8l4.2-5.8h1V55
		h1.5V55.9z M71.7,55v-4.4L68.6,55H71.7z M39.9,65.6c0.5,1.4,0.3,2.5,0,3c-0.2,0.4-0.8,0.5-1,0.2c-0.2-0.3-0.1-0.6,0.2-0.9
		c0.3-0.4,0.6-1.4,0.6-2.3H39.9z M42.1,63.3c0,0.2-0.1,0.3-0.5,0.4v11.1c0,0.1-0.5,0.4-0.9,0.4h-0.2v-12L42.1,63.3z M42,65.4
		c2.1,1.3,1.1,3,0.3,2.2c0-0.7-0.2-1.5-0.5-2.1L42,65.4z M50,68.6c0,0,0.7,0.6,1.1,1.1c0,0.1-0.2,0.2-0.3,0.2h-4.3
		C46,72.1,44.8,74,42,75.1l-0.1-0.2c2.2-1.3,3-3.1,3.3-5.1h-2.8l-0.1-0.4h2.9c0.1-0.6,0.1-1.2,0.1-1.9v-1.8h-2.1l-0.1-0.4h2.2v-2.3
		l1.7,0.2c0,0.2-0.1,0.3-0.5,0.4v1.8H48l0.6-0.6l1.2,1c-0.1,0.1-0.3,0.2-0.5,0.3v3.5h0.1L50,68.6z M46.6,67.7c0,0.6,0,1.2-0.1,1.9
		h1.6v-3.7h-1.5V67.7z M47.1,70.8c4.4,2.3,3.5,5,2.2,4C49,73.6,47.8,72,47,70.9L47.1,70.8z M56.2,69.5c-0.1,0.1-0.2,0.2-0.5,0.2
		c-0.3,0.9-0.9,1.9-1.7,2.7c0,0,0.1,0.1,0.1,0.1c1.1,1,2.6,1.1,5.3,1.1c1.5,0,2.8,0,4.3-0.1v0.2c-0.5,0.1-0.8,0.5-0.9,1h-3.9
		c-2.8,0-3.9-0.4-5-1.8c-0.2-0.2-0.3-0.2-0.4,0c-0.2,0.4-0.6,1.1-0.9,1.7c0,0.1,0,0.2-0.1,0.3l-0.9-1.2c0.6-0.4,1.5-1.2,1.9-1.3
		c0.5-0.8,0.8-1.8,1-2.8h-1.2L53,70L52,69.2c0.1-0.1,0.3-0.2,0.5-0.2c0.4-0.6,1-1.5,1.4-2.1h-1.9l-0.1-0.4h2.1l0.5-0.6l1.1,1
		c-0.1,0.1-0.4,0.2-0.7,0.2c-0.4,0.6-1,1.5-1.5,2.1h1l0.6-0.6L56.2,69.5z M52.5,63.3c3.1,0.6,2.2,2.8,1,2.1c-0.1-0.7-0.7-1.5-1.1-2
		L52.5,63.3z M55.4,64.8h3.4v-1.7l1.6,0.2c0,0.2-0.1,0.3-0.5,0.4v1.2h1.4l0.7-0.9c0,0,0.8,0.6,1.3,1c0,0.1-0.2,0.2-0.3,0.2H60v1.2
		h1.2l0.6-0.6l1.2,0.9c-0.1,0.1-0.3,0.2-0.5,0.3v2.2c0,0.1-0.6,0.3-0.9,0.3h-0.2V69H60v0.6c3.7,0.9,3.1,3.2,1.9,2.6
		c-0.3-0.7-1.2-1.6-1.9-2.3V73c0,0.1-0.5,0.4-0.9,0.4h-0.2v-2.9c-0.8,0.9-1.9,1.6-3,2.2l-0.1-0.2c1.2-0.9,2.3-2.2,2.9-3.6h-1.1v0.3
		c0,0.1-0.5,0.3-0.9,0.3h-0.2v-3.8l1.2,0.5h1.4v-1.2h-3.3L55.4,64.8z M58.8,68.6v-1.8h-1.5v1.8H58.8z M60,66.8v1.8h1.3v-1.8H60z
		 M69.2,69.5c-0.1,0.1-0.2,0.2-0.5,0.2c-0.3,0.9-0.9,1.9-1.8,2.7c0.1,0,0.1,0.1,0.2,0.1c1.1,1,2.6,1.1,5.3,1.1c1.5,0,2.8,0,4.3-0.1
		v0.2c-0.5,0.1-0.8,0.5-0.8,1H72c-2.7,0-3.9-0.4-5-1.8c-0.2-0.2-0.3-0.2-0.4,0c-0.2,0.4-0.6,1.1-0.9,1.7c0,0.1,0,0.2-0.1,0.3
		l-0.9-1.2c0.6-0.4,1.5-1.2,1.9-1.3c0.5-0.8,0.8-1.8,1-2.8h-1.1L66,70l-1-0.8c0.1-0.1,0.3-0.2,0.5-0.2c0.4-0.6,1-1.5,1.3-2.2h-2
		l-0.1-0.4h2.1l0.6-0.6l1.1,1c-0.1,0.1-0.4,0.2-0.7,0.2c-0.3,0.6-0.9,1.5-1.4,2.1h0.9l0.6-0.6L69.2,69.5z M65.4,63.3
		c3.2,0.5,2.3,2.8,1.1,2.1c-0.1-0.7-0.7-1.5-1.2-2L65.4,63.3z M68.7,65.7l-0.1-0.4h4.2c0.2-0.7,0.5-1.6,0.6-2.2l1.7,0.4
		c0,0.1-0.2,0.3-0.5,0.3c-0.3,0.5-0.9,1.1-1.5,1.5h1.2l0.7-0.9c0,0,0.8,0.6,1.3,1.1c0,0.1-0.2,0.2-0.3,0.2h-3.4
		c-0.2,0.4-0.5,0.9-0.8,1.2h2l0.6-0.6l1.2,0.9c-0.1,0.1-0.3,0.2-0.5,0.3v5.3c0,0.1-0.6,0.3-1,0.3H74v-0.8h-3.3v0.5
		c0,0.1-0.5,0.4-1,0.4h-0.2v-6.8l1.2,0.5h0.5c0-0.4,0.1-0.9,0.1-1.2H68.7z M69.8,63.1c2.8,0.6,1.7,2.7,0.7,1.9
		c-0.1-0.6-0.5-1.4-0.8-1.8L69.8,63.1z M70.6,67.3v1.3H74v-1.3H70.6z M70.6,70.2H74v-1.3h-3.3V70.2z M74,71.9v-1.3h-3.3v1.3H74z
		 M80.9,72.8c0.6-0.1,1.3-0.3,2-0.5l0,0.2c-1.1,0.5-2.6,1.2-4.7,2.1c-0.1,0.2-0.2,0.3-0.3,0.3l-0.6-1.4c0.2,0,0.5-0.1,0.8-0.1v-4.6
		l1.3,0.1c0,0.1-0.1,0.2-0.3,0.3v4l0.7-0.2v-5.5h-0.5v0.2c0,0.1-0.3,0.3-0.9,0.3h-0.2v-4.6l1.3,0.5H81l0.5-0.6l1.2,0.9
		c-0.1,0.1-0.3,0.2-0.5,0.2v3.1c0,0.1-0.6,0.3-0.9,0.3h-0.2v-0.4h-0.3v2h0.3l0.6-0.9c0,0,0.7,0.6,1.1,1c0,0.1-0.2,0.2-0.3,0.2h-1.7
		V72.8z M79.2,67.2h1.9v-2.7h-1.9V67.2z M88.7,65.1c-0.1,0.1-0.2,0.2-0.5,0.2c-0.4,0.9-1,1.8-1.7,2.6c0.9,0.6,2,1.1,3.3,1.4l0,0.1
		c-0.5,0.2-0.8,0.5-0.9,1.1c-0.4-0.2-0.8-0.3-1.1-0.5l0.9,0.7c-0.1,0.1-0.2,0.2-0.5,0.2v3.8c0,0.1-0.4,0.3-1,0.3h-0.2v-0.9h-2.7
		v0.6c0,0.1-0.4,0.3-1,0.3h-0.2v-4.8c-0.4,0.2-0.9,0.4-1.4,0.5l-0.1-0.2c1.5-0.7,2.7-1.6,3.6-2.8c-0.4-0.5-0.7-1.1-1-1.7
		c-0.6,0.7-1.2,1.3-1.9,1.7l-0.2-0.1c1-1.1,1.8-2.8,2.3-4.7l1.6,0.5c-0.1,0.2-0.2,0.3-0.5,0.3c-0.1,0.3-0.3,0.6-0.4,0.8h1.6
		l0.7-0.7L88.7,65.1z M87.4,69.9c-0.7-0.4-1.2-0.8-1.6-1.3c-0.7,0.6-1.4,1.1-2.3,1.6l1,0.4h2.5L87.4,69.9z M86.9,73.8v-2.9h-2.7
		v2.9H86.9z M85,65.1c-0.2,0.3-0.3,0.5-0.5,0.8c0.3,0.5,0.7,1,1.2,1.4c0.5-0.7,0.9-1.4,1.2-2.2H85z" />
              <path class="st2-66" d="M178.1,197.9l-0.1,0.3c0.1,0.1,0.7,0.5,1.1,0.8c0,0.1-0.2,0.2-0.3,0.2h-3.4v0.5h3.5l0.6-0.6l1.1,1.1
		c-0.1,0.1-0.2,0.1-0.5,0.1c-0.3,0.3-0.9,0.7-1.2,0.9l-0.2-0.1c0.1-0.3,0.2-0.7,0.3-1h-1.6c0.1,0.1,0.6,0.5,1,0.7
		c0,0.1-0.2,0.2-0.3,0.2l-3.1,0.1v0.1c0,0.1,0,0.2,0.4,0.2h1.2c0.5,0,0.9,0,1.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3-0.1h0.1l0.1,0
		c0.3,0.1,0.5,0.2,0.5,0.3c0,0.3-0.2,0.4-0.7,0.4l1,0.8c-0.1,0.1-0.2,0.2-0.4,0.2v1.9c0,0,0,0.1-0.1,0.1l1.1,0.8
		c-0.1,0.1-0.2,0.2-0.4,0.2v1.4h0.1l0.5-0.7c0,0,0.6,0.5,0.9,0.9c0,0.1-0.2,0.2-0.3,0.2h-10.1l-0.1-0.4h1.4v-2.4l0.6,0.2v-3.2
		l1.2,0.5h4.2l0.5-0.5c-0.4,0-0.9,0-1.6,0h-1.3c-1.1,0-1.3-0.2-1.3-0.8v-0.1l-2,0.1l-0.1-0.4l2.2-0.1v-0.7l1.4,0.1
		c0,0.2-0.1,0.3-0.4,0.3v0.2l1.9-0.1l0.5-0.7h-5.9v2c0,1.8-0.2,4.4-2.1,6.1l-0.2-0.1c1.1-1.8,1.2-4,1.2-6v-2.9l1.3,0.5h2.6v-1.7
		l1.5,0.2c0,0.1-0.1,0.2-0.4,0.3v0.3h2.1l0.3-0.3c-0.2,0.1-0.6,0.1-1,0l0.1-0.7h-1.4l-0.1-0.4h1.6l0.2-1.2l1.6,0.3
		c0,0.2-0.2,0.3-0.5,0.3l-0.1,0.6h1.2l0.6-0.8c0,0,0.7,0.6,1.1,1c0,0.1-0.2,0.2-0.3,0.2H178.1z M169.3,197.5h2.3l-0.1-1.2l1.6,0.1
		c0,0.2-0.1,0.3-0.4,0.4l0.1,0.7h0.7l0.6-0.8c0,0,0.7,0.6,1.1,1c0,0.1-0.2,0.2-0.3,0.2h-2l0.1,0.6c-0.2,0.2-0.6,0.3-1.1,0.3
		l-0.1-0.9h-2.3L169.3,197.5z M178.7,205.2c-0.2,0.1-0.5,0.2-0.8,0.2h-0.2v-0.3h-4.4v0.1c0,0.1-0.4,0.3-0.8,0.3l0.4,0.2h5.3
		L178.7,205.2z M172.8,207.6h1.2V206h-1.2V207.6z M173.4,203.1v0.6h1.7v-0.6H173.4z M175.1,204.7v-0.7h-1.7v0.7H175.1z M175,207.6
		h1.2V206H175V207.6z M176.1,203.1v0.6h1.6v-0.6H176.1z M177.7,204.1h-1.6v0.7h1.6V204.1z M178.3,207.6V206h-1.2v1.6H178.3z
		 M182.1,199.4c3.1,0.3,2.2,2.6,1,1.8c-0.2-0.6-0.7-1.3-1.2-1.7L182.1,199.4z M185.3,200.2c2.2,1.8,1.1,3.7,0.3,2.8
		c0.1-0.9-0.2-2-0.5-2.8c-0.4,1.4-0.8,3-0.9,3.5c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.6,0.5,1.2,0.4,2.3c0,0.6-0.4,1-0.9,1
		c-0.3,0-0.5-0.2-0.6-0.7c0.2-1.3,0.1-2.5-0.1-2.7c-0.2-0.1-0.4-0.2-0.6-0.2v-0.3h0.6c0.2,0,0.2,0,0.3-0.3c0.2-0.5,0.2-0.5,2.1-5.6
		l0.2,0.1l-0.4,1.6L185.3,200.2z M182.9,196.4c3.2,0.4,2.3,2.7,1.1,1.9c-0.2-0.6-0.7-1.4-1.2-1.8L182.9,196.4z M188.2,196.5
		c0,0.2-0.1,0.3-0.5,0.4v5.2c0,2.6-0.6,4.8-2.8,6.2l-0.2-0.1c1.5-1.6,1.9-3.6,1.9-6v-5.7L188.2,196.5z M188.1,200.2
		c2,1.7,1,3.5,0.3,2.7c0.1-0.9-0.2-1.9-0.5-2.7L188.1,200.2z M190.9,197c0,0.2-0.1,0.3-0.4,0.3v10c0,0.1-0.5,0.4-0.8,0.4h-0.2
		v-10.8L190.9,197z M190.7,200.2c2.1,1.7,1.3,3.5,0.5,2.7c0-0.9-0.3-2-0.7-2.7L190.7,200.2z M193.7,196.5c0,0.2-0.1,0.3-0.4,0.3
		v10.9c0,0.1-0.5,0.4-0.9,0.4h-0.2v-11.8L193.7,196.5z M199.2,201.7c-0.4,0.3-0.9,0.6-1.5,0.9v4.1c0,0.9-0.2,1.4-1.5,1.5
		c0-0.4-0.1-0.7-0.2-0.9c-0.2-0.2-0.4-0.3-0.9-0.4v-0.2c0,0,1.1,0.1,1.3,0.1c0.2,0,0.3-0.1,0.3-0.2v-3.3c-0.3,0.2-0.6,0.4-1,0.6
		c0,0.2-0.1,0.3-0.3,0.3l-0.6-1.5c0.4-0.1,1-0.3,1.8-0.5v-2.7H195l-0.1-0.4h1.6v-3l1.6,0.2c0,0.2-0.2,0.3-0.5,0.4v2.5h0.1l0.6-0.9
		c0,0,0.6,0.6,1,1.1c0,0.1-0.2,0.2-0.3,0.2h-1.4v2.3c0.5-0.1,1-0.3,1.4-0.4L199.2,201.7z M203,206.7c0.4,0.1,0.9,0.1,1.4,0.1
		c0.6,0,2,0,2.6,0v0.2c-0.4,0.1-0.6,0.5-0.6,1h-2.1c-2.1,0-3.4-0.5-4.1-2.4c-0.5,1.3-1.3,2.2-2.3,2.7l-0.2-0.1
		c1-1.1,1.6-2.6,1.7-4.6l1.7,0.3c0,0.2-0.2,0.3-0.5,0.3c-0.1,0.4-0.2,0.8-0.3,1.1c0.4,0.6,0.8,1,1.5,1.2v-3.5h-2.4l-0.1-0.4h2.5
		v-1.4h-3.1l-0.1-0.4h3.2v-1.3h-2.3l-0.1-0.4h2.4v-1.1h-2.9l-0.1-0.4h3v-1.4l1.6,0.2c0,0.2-0.1,0.3-0.5,0.4v0.9h1.7l0.7-0.9
		c0,0,0.8,0.6,1.3,1.1c0,0.1-0.2,0.2-0.3,0.2H203v1.1h1.3l0.6-0.6l1.2,0.9c-0.1,0.1-0.2,0.2-0.5,0.2v1.1h0.1l0.5-0.8
		c0,0,0.6,0.6,0.9,0.9c0,0.1-0.2,0.2-0.3,0.2h-1.2v1.9c0,0.1-0.6,0.3-1,0.3h-0.2v-0.5H203v1.6h1.2l0.7-0.9c0,0,0.8,0.6,1.2,1
		c0,0.1-0.2,0.2-0.3,0.2H203V206.7z M203,200.8h1.5v-1.3H203V200.8z M203,202.6h1.5v-1.4H203V202.6z M212,202.7
		c-0.1,0.1-0.2,0.1-0.5,0.2c-0.2,0.9-0.8,2-1.6,2.8c0.1,0,0.1,0.1,0.2,0.1c1.1,1,2.6,1.2,5.3,1.1c1.5,0,2.8,0,4.3-0.1v0.2
		c-0.5,0.1-0.8,0.5-0.9,1h-3.9c-2.7,0-3.9-0.5-5-1.8c-0.2-0.2-0.3-0.2-0.4,0c-0.2,0.4-0.6,1.1-0.9,1.7c0,0.1,0,0.2-0.1,0.3
		l-0.9-1.2c0.6-0.4,1.5-1.1,1.9-1.3c0.4-0.8,0.8-1.9,0.9-2.9h-1l-0.4,0.4l-0.9-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.4-0.6,1-1.5,1.4-2.1
		h-2l-0.1-0.4h2.1l0.5-0.6l1.1,1c-0.1,0.1-0.3,0.2-0.6,0.2c-0.3,0.6-1,1.5-1.4,2.1h0.7l0.6-0.6L212,202.7z M208.6,196.4
		c3,0.7,2.1,2.8,1,2c-0.1-0.7-0.6-1.5-1.1-2L208.6,196.4z M212.7,197.5c0,0.6-0.3,1.1-0.7,1.3c-0.4,0.2-0.9,0.1-1-0.3
		c-0.1-0.4,0.1-0.7,0.5-0.8c0.4-0.2,0.8-0.7,0.8-1.2l0.2,0c0.1,0.3,0.2,0.5,0.2,0.7h5.2l0.6-0.6l1.1,1.1c-0.1,0.1-0.2,0.1-0.5,0.2
		c-0.2,0.3-0.6,0.6-1,0.9c0.2,0.2,0.5,0.4,0.7,0.6c0,0.1-0.2,0.2-0.3,0.2h-2.8v1h1.2l0.6-0.6l1.2,0.9c-0.1,0.1-0.3,0.2-0.5,0.3v2.9
		c0,0.1-0.6,0.3-0.9,0.3h-0.2v-0.4h-1.4v1.1h1.7l0.7-0.8c0,0,0.8,0.6,1.2,1c0,0.1-0.2,0.2-0.3,0.2h-3.2v1.2c0,0.1-0.5,0.4-0.9,0.4
		h-0.2v-1.6h-3l-0.1-0.4h3.1v-1.1h-1.4v0.2c0,0.1-0.5,0.3-0.9,0.3h-0.2v-4.4l1.2,0.5h1.3v-1h-2.7l-0.1-0.4h2.8v-1.2l1.6,0.2
		c0,0.2-0.1,0.3-0.5,0.4v0.7h1.3l0.6-0.8l0.2,0.1c0-0.3,0.1-0.6,0.1-0.9H212.7z M213.3,200.7v1.1h1.4v-1.1H213.3z M214.6,203.3
		v-1.1h-1.4v1.1H214.6z M215.8,200.7v1.1h1.4v-1.1H215.8z M217.1,203.3v-1.1h-1.4v1.1H217.1z" />
              <path class="st2" d="M105.6,137.8l-6.6,0c1.3,3.3,3.7,5.5,7.8,6.4c-0.5,0.5-1.3,1.6-1.6,2.3c-3.8-1.1-6.1-3.2-7.7-6.3
		c-1.1,2.6-3.3,4.9-8,6.3c-0.3-0.6-1-1.6-1.5-2.1c5-1.5,6.9-4.1,7.5-6.7l-6.4,0l0-2.4l6.7,0l0-3.6l-7.3,0l0-2.4l17.5,0l0,2.4
		l-7.7,0l0,3.6l7.1,0L105.6,137.8z M115.5,132.8c0,0.2-0.2,0.3-0.5,0.4c-0.5,2.6-1.4,4.7-6.3,5.8c-0.2-0.6-0.8-1.5-1.3-2
		c4.3-0.8,4.9-2.2,5.2-4.5L115.5,132.8z M117.6,141l0,2.9l7.5,0l0,2.1l-17.3,0l0-2.1l7.4,0l0-2.9l-5.2,0l0-2.1l13.2,0l0,2.1
		L117.6,141z M110.4,132.2l0,2.3l-2.3,0l0-4.5l7.1,0l0-2.1l3,0.2c0,0.2-0.2,0.3-0.5,0.4l0,1.6l7.2,0l0,4.5l-2.4,0l0-2.3
		L110.4,132.2z M117.6,132.4l2.8,0.1c0,0.2-0.2,0.3-0.5,0.4l0,2.6c0,0.5,0.1,0.6,0.8,0.6l2,0c0.5,0,0.6-0.2,0.7-1.1
		c0.5,0.3,1.4,0.7,2,0.8c-0.3,1.8-0.9,2.3-2.5,2.3l-2.6,0c-2.3,0-2.8-0.6-2.8-2.6L117.6,132.4z M142.3,131.2l2,1.1
		c-0.1,0.2-0.3,0.3-0.4,0.3c-2.1,3.6-5.7,7.7-9.3,10.5c1.8,0.8,4,0.8,6.5,0.8c1.3,0,3.1,0,4,0c-0.3,0.5-0.7,1.6-0.8,2.3l-4.5,0
		c-4.1,0-7.1-0.7-9.1-3.4c-0.6,1.1-1.3,2.3-1.9,3.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3-0.1,0.4l-2.4-1.6c1.4-1.6,2.9-4.2,3.7-6.2
		l2.2,0.9l-0.5,1c0.2,0.5,0.5,0.9,0.8,1.2c2.8-2.2,5.8-5.4,7.6-8.1l-12.6,0l0-2.3l6.8,0l0-3.4l2.9,0.2c0,0.2-0.2,0.3-0.5,0.4l0,2.8
		l5.1,0L142.3,131.2z M149.5,142.7c0,1.2,0.3,1.4,2.6,1.4l6.9,0c1.8,0,2.1-0.3,2.4-2.1c0.6,0.3,1.6,0.7,2.3,0.8
		c-0.4,2.8-1.3,3.6-4.5,3.6l-7,0c-3.9,0-5.1-0.7-5.1-3.6l0-8.2l15.1,0l0,6.8l-2.4,0l0-0.6l-10.4,0L149.5,142.7z M161,128.7l0,4.8
		l-12.8,0l0-4.8L161,128.7z M153.4,136.6l-3.9,0l0,2.1l3.9,0L153.4,136.6z M158.6,131.8l0-1.3l-7.9,0l0,1.3L158.6,131.8z
		 M159.9,138.7l0-2.1l-4.1,0l0,2.1L159.9,138.7z M100.4,112.6c-0.7-0.6-2.2-1.5-3.4-2l0.7-1c1.2,0.5,2.7,1.3,3.4,1.9L100.4,112.6z
		 M97.5,121.5c0.8-1.6,2-4.2,2.9-6.5l1.1,0.7c-0.8,2.1-1.8,4.6-2.6,6.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1-0.1,0.2L97.5,121.5z
		 M101.1,107.3c-0.7-0.7-2-1.6-3.2-2.2l0.8-1c1.2,0.5,2.6,1.4,3.3,2.1L101.1,107.3z M104.7,104.3c1,0.1,1.3,0.1,1.7,0.1
		c0,0.1-0.1,0.2-0.4,0.3l0,6.7c0,4.1-0.4,8-3.6,11.1c-0.2-0.3-0.8-0.6-1.1-0.8c3-2.9,3.4-6.5,3.4-10.3L104.7,104.3z M101.4,114.5
		c1-1.1,1.4-3.1,1.7-4.9l1,0.3c-0.3,1.8-0.7,4-1.7,5.2L101.4,114.5z M107.5,114.7c-0.2-1.2-0.7-3.1-1.3-4.5l1-0.3
		c0.6,1.4,1.2,3.2,1.4,4.4L107.5,114.7z M109,104.6l1.7,0.1c0,0.1-0.1,0.2-0.4,0.3l0,17.1l-1.3,0L109,104.6z M112.4,114.7
		c-0.3-1.3-1.2-3.3-2.1-4.8l1-0.4c0.9,1.4,1.8,3.4,2.2,4.7L112.4,114.7z M113.5,104.3l1.7,0.1c0,0.1-0.1,0.2-0.4,0.3l0,17.8l-1.3,0
		L113.5,104.3z M135.7,113l0,1.3l-8.5,0l0,6.4c0,1-0.3,1.4-1,1.6c-0.7,0.2-2,0.2-4,0.2c-0.1-0.4-0.3-1-0.6-1.4
		c1.1,0,2.1,0.1,2.8,0.1c1.3,0,1.4,0,1.4-0.5l0-6.3l-8.5,0l0-1.3l8.5,0l0-3.3l0.8,0c1.6-0.8,3.3-2,4.6-3.1l-11.8,0l0-1.3l13.3,0
		l0.2-0.1l1.2,0.8c-0.1,0.1-0.2,0.1-0.3,0.2c-1.6,1.6-4.3,3.5-6.6,4.6l0,2.2L135.7,113z M137.9,109.5c1.3,0.5,3,1.4,3.8,2.1
		l-0.8,1.1c-0.8-0.7-2.4-1.6-3.8-2.2L137.9,109.5z M137.8,121.3c1-1.6,2.5-4.3,3.6-6.6l1,0.9c-1,2.1-2.3,4.7-3.3,6.2
		c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.2L137.8,121.3z M141.9,107.3c-0.8-0.7-2.4-1.7-3.7-2.4l0.8-1c1.3,0.6,2.9,1.5,3.7,2.2
		L141.9,107.3z M155.9,116.4l0,1.3l-5.9,0l0,4.8l-1.4,0l0-4.8l-5.7,0l0-1.3l5.7,0l0-3.2l-4.6,0l0-1.3l4.6,0l0-3.1l-5.2,0l0-1.3l3,0
		c-0.3-0.9-1.1-2.2-1.8-3.2l1.2-0.6c0.8,1,1.6,2.4,2,3.4l-0.9,0.4l4.4,0l-0.6-0.3c0.7-0.9,1.5-2.5,2-3.6l1.6,0.7
		c-0.1,0.1-0.2,0.2-0.4,0.2c-0.4,0.9-1.2,2.1-1.8,3l3.4,0l0,1.3l-5.4,0l0,3.1l4.7,0l0,1.3l-4.7,0l0,3.2L155.9,116.4z" />
            </g>
            <g id="icon" class="anicon">
              <g>
                <g>
                  <path class="st2" d="M54.1,58.4c-1.1,0.6-3,0.6-4.1,0c0,0-2-1.1-5.2-6.6c-3.2-5.5-3.1-7.8-3.1-7.8c0-1.3,1-2.8,2.2-3.4
					c0,0,1.8-0.9,8.2-0.9c6.4,0,8.2,0.9,8.2,0.9c1.2,0.6,2.2,2.1,2.2,3.4c0,0,0.1,2.2-3.1,7.8C56.1,57.4,54.1,58.4,54.1,58.4z" />
                  <path class="st4" d="M54.1,58.4c-1.1,0.6-3,0.6-4.1,0c0,0-2-1.1-5.2-6.6c-3.2-5.5-3.1-7.8-3.1-7.8c0-1.3,1-2.8,2.2-3.4
					c0,0,1.8-0.9,8.2-0.9c6.4,0,8.2,0.9,8.2,0.9c1.2,0.6,2.2,2.1,2.2,3.4c0,0,0.1,2.2-3.1,7.8C56.1,57.4,54.1,58.4,54.1,58.4z" />
                </g>
                <g>
                  <path class="st5" d="M54.1,58.4c-1.1,0.6-3,0.6-4.1,0c0,0-2-1.1-5.2-6.6c-3.2-5.5-3.1-7.8-3.1-7.8c0-1.3,1-2.8,2.2-3.4
					c0,0,1.8-0.9,8.2-0.9c6.4,0,8.2,0.9,8.2,0.9c1.2,0.6,2.2,2.1,2.2,3.4c0,0,0.1,2.2-3.1,7.8C56.1,57.4,54.1,58.4,54.1,58.4z" />
                  <path class="st6" d="M54.1,58.4c-1.1,0.6-3,0.6-4.1,0c0,0-2-1.1-5.2-6.6c-3.2-5.5-3.1-7.8-3.1-7.8c0-1.3,1-2.8,2.2-3.4
					c0,0,1.8-0.9,8.2-0.9c6.4,0,8.2,0.9,8.2,0.9c1.2,0.6,2.2,2.1,2.2,3.4c0,0,0.1,2.2-3.1,7.8C56.1,57.4,54.1,58.4,54.1,58.4z" />
                </g>
                <g>
                  <path class="st2" d="M51.7,45.4l-1.7,0.2c0-0.4-0.2-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.7,0.2-1,0.5
					c-0.3,0.3-0.4,1-0.5,2.1c0.4-0.5,1-0.8,1.7-0.8c0.7,0,1.4,0.3,1.9,0.8c0.5,0.6,0.8,1.3,0.8,2.2c0,1-0.3,1.7-0.8,2.3
					c-0.6,0.6-1.3,0.9-2.1,0.9c-0.9,0-1.7-0.4-2.3-1.1c-0.6-0.7-0.9-1.9-0.9-3.6c0-1.7,0.3-2.9,0.9-3.7C47.2,43.4,48,43,49,43
					c0.7,0,1.3,0.2,1.8,0.6C51.3,44,51.5,44.6,51.7,45.4z M47.6,49.2c0,0.6,0.1,1,0.4,1.3c0.3,0.3,0.6,0.5,0.9,0.5
					c0.3,0,0.6-0.1,0.8-0.4c0.2-0.3,0.3-0.7,0.3-1.3c0-0.6-0.1-1-0.4-1.3c-0.2-0.3-0.5-0.4-0.9-0.4c-0.3,0-0.6,0.1-0.9,0.4
					C47.8,48.3,47.6,48.7,47.6,49.2z" />
                  <path class="st2" d="M55.7,52.4v-1.9h-3.8v-1.6l4-5.9h1.5v5.9h1.2v1.6h-1.2v1.9H55.7z M55.7,48.9v-3.2l-2.1,3.2H55.7z" />
                </g>
              </g>
              <g>

                <ellipse transform="matrix(0.923 -0.3849 0.3849 0.923 -4.2686 77.4216)" class="st7" cx="191.3" cy="49.4" rx="10.3" ry="10.3" />
                <g>
                  <g>
                    <g>
                      <path class="st8" d="M183.9,49.9l1.4-1.2c0.4-1.3,1.1-2.2,2.8-3.7c1.8-1.6,8.1-3.2,8.3,2.8c1.4-0.1,2.6,0,2.6,0s0.5-4.4-4-5.5
							C190.5,41.1,185,44.6,183.9,49.9z" />
                      <polygon class="st9" points="184.5,50.3 189.4,50.3 192,47.7 195.7,47.7 195.3,46.8 191.7,46.8 190.2,48.2 189.4,48.2
							191.3,46.3 194.4,46.3 194,45.5 190.3,45.5 187.4,48.3 186.4,48.3 						" />
                      <path class="st9" d="M198.8,49l-1.4,1.2c-0.4,1.3-1.1,2.2-2.8,3.7c-1.8,1.6-8.1,3.2-8.3-2.8c-1.4,0.1-2.6,0-2.6,0
							s-0.5,4.4,4,5.5C192.2,57.7,197.6,54.2,198.8,49z" />
                      <polygon class="st8" points="198.2,48.5 193.2,48.5 190.6,51.1 186.9,51.1 187.4,52 191,52 192.4,50.6 193.2,50.6 191.3,52.5
							188.2,52.5 188.7,53.3 192.4,53.3 195.3,50.5 196.2,50.5 						" />
                    </g>
                  </g>
                </g>
              </g>
              <g>

                <ellipse transform="matrix(0.923 -0.3849 0.3849 0.923 -54.7318 89.127)" class="st7" cx="195.3" cy="181.3" rx="10.3" ry="10.3" />
                <g>
                  <g>
                    <g>
                      <path class="st8" d="M187.9,181.8l1.4-1.2c0.4-1.3,1.1-2.2,2.8-3.7c1.8-1.6,8.1-3.2,8.3,2.8c1.4-0.1,2.6,0,2.6,0
							s0.5-4.4-4-5.5C194.5,173,189,176.5,187.9,181.8z" />
                      <polygon class="st9" points="188.5,182.3 193.4,182.3 196,179.7 199.7,179.7 199.3,178.8 195.7,178.8 194.2,180.1
							193.4,180.1 195.4,178.2 198.4,178.2 198,177.5 194.3,177.5 191.4,180.2 190.5,180.2 						" />
                      <path class="st9" d="M202.8,180.9l-1.4,1.2c-0.4,1.3-1.1,2.2-2.8,3.7c-1.8,1.6-8.1,3.2-8.3-2.8c-1.4,0.1-2.6,0-2.6,0
							s-0.5,4.4,4,5.5C196.2,189.6,201.6,186.1,202.8,180.9z" />
                      <polygon class="st8" points="202.2,180.4 197.2,180.4 194.6,183 191,183 191.4,183.9 195,183.9 196.5,182.5 197.2,182.5
							195.3,184.4 192.2,184.4 192.7,185.2 196.4,185.2 199.3,182.5 200.2,182.5 						" />
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path class="st10" d="M70.6,176.2c0-2.9-2.3-5.2-5.2-5.2c-0.3,0-0.6,0-0.9,0.1c-0.3-2.5-2.5-4.5-5.1-4.5
						c-2.6,0-4.8,1.9-5.1,4.5c-0.3-0.1-0.6-0.1-1-0.1c-2.9,0-5.2,2.3-5.2,5.2c0,2.1,1.2,3.8,3,4.7c-0.4,0.7-0.6,1.5-0.6,2.4
						c0,2.9,2.3,5.2,5.2,5.2c1.4,0,2.8-0.6,3.7-1.6c0.9,1,2.2,1.6,3.7,1.6c2.9,0,5.2-2.3,5.2-5.2c0-0.9-0.2-1.7-0.6-2.4
						C69.4,180,70.6,178.2,70.6,176.2z" />
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path class="st2" d="M70.6,176.2c0-2.9-2.3-5.2-5.2-5.2c-0.3,0-0.6,0-0.9,0.1c-0.3-2.5-2.5-4.5-5.1-4.5
							c-2.6,0-4.8,1.9-5.1,4.5c-0.3-0.1-0.6-0.1-1-0.1c-2.9,0-5.2,2.3-5.2,5.2c0,2.1,1.2,3.8,3,4.7c-0.4,0.7-0.6,1.5-0.6,2.4
							c0,2.9,2.3,5.2,5.2,5.2c1.4,0,2.8-0.6,3.7-1.6c0.9,1,2.2,1.6,3.7,1.6c2.9,0,5.2-2.3,5.2-5.2c0-0.9-0.2-1.7-0.6-2.4
							C69.4,180,70.6,178.2,70.6,176.2z" />
                    </g>
                    <g>
                      <path class="st11" d="M70.6,176.2c0-2.9-2.3-5.2-5.2-5.2c-0.3,0-0.6,0-0.9,0.1c-0.3-2.5-2.5-4.5-5.1-4.5
							c-2.6,0-4.8,1.9-5.1,4.5c-0.3-0.1-0.6-0.1-1-0.1c-2.9,0-5.2,2.3-5.2,5.2c0,2.1,1.2,3.8,3,4.7c-0.4,0.7-0.6,1.5-0.6,2.4
							c0,2.9,2.3,5.2,5.2,5.2c1.4,0,2.8-0.6,3.7-1.6c0.9,1,2.2,1.6,3.7,1.6c2.9,0,5.2-2.3,5.2-5.2c0-0.9-0.2-1.7-0.6-2.4
							C69.4,180,70.6,178.2,70.6,176.2z" />
                    </g>
                  </g>
                  <g>
                    <path class="st12" d="M59.1,172.2h2v12h-2.3V175l-1.9,1.7l-1.1-1.6L59.1,172.2z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div class="title1">天空之翼</div>
        <h3 class="title2">五泰輕軌快意通 搶搭增值特快車</h3>
        <h3 class="subtitle">
          輻射雙北 價值連城
        </h3>
        <h3 class="desc">
散步五分鐘，翻轉洲子洋最強未來助力「五泰輕軌」F9五股站近在咫尺，為您銜接機場捷運、新蘆線、環狀線，「天空之邑」坐落一高、台64、台65最前沿，為您提前預約地段增值紅利，讓您輕取雙北繁華大城。
        </h3>
      </div>
    </transition>
   <!-- <img loading="lazy" src="./s4/img.png" :alt="`${info.caseName}_img`" class="circle">  -->
    <div class="btns flex-ac flex-jb">
      <h3 :class="`btn flex-c ${blockIndex === 0 ? 'active' : ''}`" @click="blockIndex = 0">
        交通新核心
      </h3>
      <h3 :class="`btn flex-c ${blockIndex === 1 ? 'active' : ''}`" @click="blockIndex = 1">
        便利捷運
      </h3>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section8 {
  width: 100%;
  height: size(965);
  min-height: size(965);
  max-height: size(965);
  position: relative;
  z-index: 8;
    background: #fff;
  // background-color: #fff;
  // min-height: size(900);
  // background-image: url('./s2/bg.jpg');
  // background-size: 100% 100%;
  // background-position: 0 0;
  // background-attachment: fixed;
  // overflow: hidden;
}
.st0 {
  fill: none;
  stroke: #d38700;
  stroke-width: 3;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #d38700;
}
.st2-66 {
  fill: #606060;
}
  .st2 {
  fill: #FFF;
}
.st3 {
  fill: none;
  stroke: #d38700;
  stroke-width: 4.2543;
  stroke-miterlimit: 10;
}
.st5 {
  fill: #b4337c;
}
.st6 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.7147;
  stroke-miterlimit: 10;
}
.st7 {
  fill: #ffffff;
  stroke: #97a13b;
  stroke-width: 1.3278;
  stroke-miterlimit: 10;
}
.st8 {
  fill: #22a83b;
}
.st9 {
  fill: #7f9d26;
}
.st10 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 3.7219;
}
.st11 {
  fill: none;
  stroke: #589424;
  stroke-width: 1.5239;
}
.st12 {
  fill: #221714;
}
.an00 {
  opacity: 0;
  stroke-dashoffset: 420;
  animation: an52 1s 1s linear forwards;
}
.an01 {
  stroke-dasharray: 212;
  stroke-dashoffset: 212;
  animation: an51 1s 2.5s linear forwards;
}
.an02 {
  stroke-dasharray: 520;
  stroke-dashoffset: 520;
  animation: an51 1s 1s linear forwards;
}

.an03 {
  opacity: 0;
  stroke-dashoffset: 420;
  animation: an52 1s 0.5s linear forwards;
}

.an04 {
  stroke-dasharray: 520;
  stroke-dashoffset: 520;
  animation: an51 2s 1.5s linear forwards;
}
.anfont {
  opacity: 0;
  stroke-dashoffset: 420;
  animation: an52 1s 1.5s linear forwards;
}

.anicon {
  opacity: 0;
  stroke-dashoffset: 420;
  animation: an52 1s 1.5s linear forwards;
}

@keyframes an51 {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes an52 {
  to {
    opacity: 1;
  }
}
svg {
  @include div_r_pc(238, 238, 680, 192);
}
.title1 {
  @include div_r_pc(70, 464, 170, 307);
  font-size: size(40);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: size(4);
  text-align: left;
  padding-top: size(46);
  color: #ffffff;
  background-color: #036EB7;
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.title2 {
  @include div_r_pc(34, 558, 180, 256);
  font-size: size(25);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: size(5);
  text-align: left;
  color: #036EB7;
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.subtitle {
  @include div_r_pc(20, 558, 250, 428);
  font-size: size(17);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: size(1);
  text-align: left;
  color: #000;
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.desc {
  @include div_r_pc(140, 566, 251, 465);
  font-size: size(16);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: size(0.9);
  text-align: left;
  color: #606060;
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.btns {
  @include img_r_pc(174 * 2 + 71, 189, 679);
}

.btn {
  width: size(174);
  height: size(28);
  cursor: pointer;
  background: #fff;
  transition: all 0.3s;
  font-weight: bold;
  font-size: size(18);
  // animation:btn 0.5s infinite alternate ;
  color: #606060;

  &.active,
  &:hover {
    box-shadow: -2px 2px 0 0 #036EB7;
    background: #fff;
    color: #036EB7;
  }
}
.circle {
  @include img_l_pc(197, 157, 180);
  animation: circle 30s linear 0s infinite;
}

@keyframes circle {
  to {
    transform: rotate(360deg);
  }
}
// begin
.fade-up-leave-to {
  transform: translateY(15px);
  opacity: 0;
  z-index: 0;
}
// end
.fade-up-enter {
  transform: translateY(55px);
  opacity: 0;
  z-index: 1;
}

.fade-up-enter-active {
  transition: all 0.5s ease;
}

.fade-up-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

/* Swipe */
.swipe {
  @include div_l_pc(1026, 667, 248, 268);
  object-fit: cover;
  // background: #0344;
}

// begin
.swipe-fade-leave-to {
  opacity: 0;
  z-index: 0;
}
// end
.swipe-fade-enter {
  opacity: 0;
  z-index: 1;
}

.swipe-fade-enter-active {
  transition: all 0.3s ease;
}

.swipe-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

// begin
// .swipe-left-leave-to {
//   margin-left: -100vw;
//   z-index: 0;
// }
// // end
// .swipe-left-enter {
//   opacity: 0.5;
//   margin-left: 0;
//   z-index: 1;
// }

// .swipe-left-enter-active {
//   transition: all 0.5s ease;
// }

// .swipe-left-leave-active {
//   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
// }

.swipe-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swipe-item {
  width: 100%;
  height: 100%;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slide-name {
    right: 2em;
    bottom: 1.2em;
    color: #fff;
    font-size: size(18);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.03em;
    text-align: left;
    text-shadow: 0 0.3em 1em #000;
  }

  // &:nth-child(1) {
  //   z-index: 1;
  //   // opacity: 1;
  // }

  // &.base {
  //   z-index: 1;
  //   opacity: 1;
  // }
  // &.active {
  //   z-index: 2;
  //   // opacity: 1;
  // }
}

.pagination {
  width: auto;
  bottom: size(24);
  right: 0;
  left: 0;
  margin: 0 auto;
  justify-content: center;
}

.pagination-dot {
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  z-index: 4;

  span {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
    position: relative;
    background-color: transparent;
    transition: all 0.5s;

    &::before {
      content: '';
      width: 60%;
      height: 60%;
      display: block;
      background: #fff;
      border-radius: 20px;
      opacity: 1;
      position: absolute;
      top: 20%;
      // transform: translateY(-50%);
      left: 20%;
      transition: all 0.3s;
      transform-origin: center;
      transform: scale(0);
    }
    &.active {
      box-shadow: none;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: #fff;
        border-radius: 20px;
        opacity: 1;
        position: absolute;
        top: 0%;
        // transform: translateY(-50%);
        left: 0%;
        transform: scale(1);
      }
    }
  }
}

.swipe-btns {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  z-index: 3;

  .prev-btn,
  .next-btn {
    width: size(20);
    cursor: pointer;
  }
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section8 {
    width: 100vw;
    height: sizem(893);
    min-height: auto;
    max-height: initial;
    //  background-image: url('./all/section_bg.jpg');
    // background-attachment: scroll;
    // background-image: url('./s2/bg.jpg');
    // background-size: 100% 100%;
    // background-position: 0 0;
    // background-attachment: fixed;
    overflow: hidden;
  }
  svg {
    @include div_l_m(190, 190, 0, 33);
    top: auto;
    bottom: sizem(47);
  }

  .title1 {
    @include div_l_m(178, 41, 92, 34);
    font-size: sizem(23);
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.91;
    letter-spacing: sizem(2.3);
    text-align: center;
    color: #ffffff;
    background-color: #036EB7;
    padding-top: 0;
    writing-mode: horizontal-tb;
    text-orientation: mixed;
  }

  .title2 {
    @include div_l_m(350, 27, 57, 34);
    font-size: sizem(18);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing:0.06em;
    text-align: left;
    color: #036EB7;
    writing-mode: horizontal-tb;
    text-orientation: mixed;
  }

  .subtitle {
    @include div_r_m(310, 30, 471, 32);
    font-size: sizem(17);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.06em;
    text-align: left;
    color: #000;
    writing-mode: horizontal-tb;
    text-orientation: mixed;
  }

  .desc {
    @include div_r_m(310, 30, 497, 32);
    font-size: sizem(15);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: normal;
    text-align: left;
    color: #606060;
    writing-mode: horizontal-tb;
    text-orientation: mixed;
  }

  .btns {
    @include img_c_m(140 * 2 + 11, 170);
  }

  .btn {
    width: sizem(140);
    height: sizem(30);
    cursor: pointer;
    background: #fff;
    transition: all 0.3s;
    font-size: sizem(19);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: sizem(1.9);
    text-align: left;
    color: #606060;

    &.active,
    &:hover {
      box-shadow: -2px 2px 0 0 #036EB7;
      background: #fff;
      color: #036EB7;
    }
  }
  .circle {
    @include img_r_m(133, 708, -35);
  }

  /* Swipe */
  .swipe {
    width: 100%;
    height: sizem(243);
    min-height: auto;
    top: sizem(210);
    bottom: auto;
    left: sizem(0);
    object-fit: cover;
  }

  // begin
  .swipe-fade-leave-to {
    opacity: 0;
    z-index: 0;
  }
  // end
  .swipe-fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .swipe-fade-enter-active {
    transition: all 0.5s ease;
  }

  .swipe-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  // begin
  // .swipe-left-leave-to {
  //   margin-left: -100vw;
  //   z-index: 0;
  // }
  // // end
  // .swipe-left-enter {
  //   opacity: 0.5;
  //   margin-left: 0;
  //   z-index: 1;
  // }

  // .swipe-left-enter-active {
  //   transition: all 0.5s ease;
  // }

  // .swipe-left-leave-active {
  //   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  // }

  .swipe-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .swipe-item {
    width: 100%;
    height: 100%;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    // &:nth-child(1) {
    //   z-index: 1;
    //   // opacity: 1;
    // }

    // &.base {
    //   z-index: 1;
    //   opacity: 1;
    // }
    // &.active {
    //   z-index: 2;
    //   // opacity: 1;
    // }
    .slide-name {
      right: auto;
      width: auto;
      top: auto;
      bottom: 1.2em;
      right: 0.5em;
      text-align: right;
      font-size: sizem(12);
      font-weight: 400;
    }
  }

  .pagination {
    width: auto;
    bottom: size(91);
    left: 0;
    right: 0;
    margin: 0 auto;
    justify-content: center;
  }

  .pagination-dot {
    padding: 5px;
    margin: 0 10px;
    cursor: pointer;
    z-index: 4;

    span {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      box-shadow: 0 0 0 1px #fff;
      position: relative;
      background-color: rgba(0, 0, 0, 0.01);
      transition: all 0.5s;

      &::before {
        content: '';
        width: 60%;
        height: 60%;
        display: block;
        background: #004ea2;
        border-radius: 20px;
        opacity: 1;
        position: absolute;
        top: 20%;
        // transform: translateY(-50%);
        left: 20%;
        transition: all 0.3s;
        transform-origin: center;
        transform: scale(0);
      }
      &.active {
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          background: #004ea2;
          border-radius: 20px;
          opacity: 1;
          position: absolute;
          top: 0%;
          // transform: translateY(-50%);
          left: 0%;
          transform: scale(1);
        }
      }
    }
  }

  .swipe-btns {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    z-index: 3;

    .prev-btn,
    .next-btn {
      width: sizem(15);
      cursor: pointer;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'
import info from '@/info'
import slider from '@/mixins/slider.js'

export default {
  name: 'section8',

  mixins: [slider],
  props: ['viewIndex'],

  data() {
    return {
      info,
      isPC,
      isMobile,
      isTablet,
      isDialog: false,
      blockIndex: 0,
      slideIndex1: 0,
      slideList: [
        {
          img: require('./s8/1.jpg'),
          name: '五楊高架',
        },
        {
          img: require('./s8/05_slider_7.jpg'),
          name: '五楊高架',
        },
      ],
      slideList1: [
        {
          img: require('./s8/3.jpg'),
          name: '捷運示意',
        },
        {
          img: require('./s8/4.jpg'),
          name: '捷運蘆洲站',
        },
      ],
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},

  watch: {
    // viewIndex() {
    //   if (this.viewIndex === 5) {
    //     this.slideIndex = 0
    //     console.log(this.slideIndex, 'slideIndex')
    //   }
    // },
  },
}
</script>
